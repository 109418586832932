import store from '@/store'
import { mapActions, mapState } from 'vuex'
// import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import PanelEntregaDiaria from '@/components/Layout/Panel_entrega_diaria/PanelEntregaDiariaView.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'EditarClientesView',
  components: {
    // PanelEntregaDiaria
  },
  props: {
    cliente: null
  },
  data: function () {
    return {
      clienteJSON: {},
      nroClienteSap: 0,
      razonSocial: '',
      nombreFantasia: '',
      telefono: [],
      contacto: '',
      direccionDespacho: '',
      territorioVenta: '',
      clienteFoco: '',
      canalVenta: '',
      analista: store.state.user,
      show: false,
      nuevoTelefono: '',
      apertura: '',
      cierre: '',
      inicioColacion: '',
      finColacion: '',
      horaRecepcion: ''
    }
  },
  watch: {
  },
  mounted () {
    this.clienteJSON = JSON.parse(this.cliente)
    this.nroClienteSap = this.clienteJSON.nro_cliente_sap
    this.razonSocial = this.clienteJSON.razon_social
    this.nombreFantasia = this.clienteJSON.nombre_fantasia
    this.telefono = this.clienteJSON.telefono
    this.contacto = this.clienteJSON.contacto
    this.direccionDespacho = this.clienteJSON.direccion_despacho
    this.territorioVenta = this.clienteJSON.territorio_venta
    this.clienteFoco = this.clienteJSON.cliente_foco
    this.canalVenta = this.clienteJSON.canal_venta
    this.apertura = this.clienteJSON.apertura
    this.cierre = this.clienteJSON.cierre
    this.inicioColacion = this.clienteJSON.inicio_colacion
    this.finColacion = this.clienteJSON.fin_colacion
    this.horaRecepcion = this.clienteJSON.hora_recepcion
  },
  methods: {
    ...mapActions('Clientes', [
      'getAllClientes', 'insertClientes', 'insertClientesSincro'
    ]),
    crearCliente () {
      console.log('crearCliente')
      const clientes = []
      const cliente = {
        nro_cliente_sap: this.nroClienteSap,
        razon_social: this.razonSocial,
        nombre_fantasia: this.nombreFantasia,
        telefono: this.telefono,
        contacto: this.contacto,
        direccion_despacho: this.direccionDespacho,
        territorio_venta: this.territorioVenta,
        cliente_foco: this.clienteFoco,
        canal_venta: this.canalVenta,
        analista_ma: this.analista,
        apertura: this.apertura,
        cierre: this.cierre,
        inicio_colacion: this.inicioColacion,
        fin_colacion: this.finColacion,
        hora_recepcion: this.horaRecepcion
      }
      console.log(cliente)
      clientes.push(cliente)
      this.show = true
      this.insertClientesSincro(clientes).then((res) => {
        this.show = false
        this.$emit('updateClientes', new Date())
      })
    },
    agregarTelefono () {
      if (this.nuevoTelefono !== '') {
        this.telefono.push(this.nuevoTelefono)
      }
    },
    borrarTelefono (index) {
      this.telefono.splice(index, index + 1)
    }
  },
  computed: {
    ...mapState('Clientes', ['clientes'])
  }
}
